import { Component, EventEmitter, HostListener, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
import { UtilitiesService } from 'src/app/utilities.service';
import { Lead } from '../models/lead';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-single-store-events',
  templateUrl: './single-store-events.component.html',
  styleUrls: ['./single-store-events.component.scss']
})
export class SingleStoreEventsComponent implements OnInit {
  storeImages: any[] = [];
  storesitestatus: any;
  storDetails: any;
  submitted = false;
  consentText: any;
  submitText: any = "Submit";
  formattedAddress: any;
  storeId:any;
  hcid:any;
  storeName:string = '';
  bannerImages: any ;
  description: string = "";
  currentBannerImg:any;
  public tenantId: string = "1";
  bannerAutoPlay: boolean = false;
  app: AppComponent;
  isModalOpen: boolean = false;
  enquiryLeadForm!: FormGroup;
  fb: FormBuilder;
  modalService: NgbModal;
  @ViewChild('thankYouModal') thankYouModal: any; 
  currentSlide = 0;
  autoplay = true;
  autoplayInterval: any;
  isAutoplayPaused: any = false;
  bannerConfiguration:any;
  constructor(private apiService: ApiService,
    public injector: Injector,
    public utils: UtilitiesService,
    private activatedRoute:ActivatedRoute,
    private route: Router,
  ) { 
    this.app = this.injector.get<AppComponent>(AppComponent);
    this.fb = this.injector.get<FormBuilder>(FormBuilder);
    this.modalService = this.injector.get<NgbModal>(NgbModal);
    
  }

  ngOnInit() {
    let emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
    this.enquiryLeadForm = this.fb.group({
      name: ['', [Validators.minLength(2), Validators.pattern('^[^<>!1234567890]*$')]],
      email: ['', [Validators.pattern(emailPattern)]],
      mobileNo: ['', [Validators.required, Validators.pattern("[0-9]*"), Validators.minLength(10)]],
      pincode: ['', [Validators.required, Validators.pattern("[0-9]*"), Validators.minLength(6)]],
      sourceName: ['Store Locator'],
      storeId: [""],
      storeName: [""],
      phoneNumber: [""],
      storeGMBLink: [""],
      consent: [false, Validators.requiredTrue]
    });
   
    this.apiService.productsObserable.subscribe(data => {
      if (data != null) {
        this.storeImages = [...Array(20)].reduce((acc) => acc.concat([...data]), []);
        setTimeout(() => {
          this.productSlider();
        }, 300)
      }
    });
    this.getBanners();
    this.activatedRoute.queryParamMap.subscribe(params => {
      const storeIdParam = params.get('id');
      if (storeIdParam) { 
        this.storeId = +storeIdParam; 
        this.getStoreName(); 
      } else {
        console.error('storeid not found in URL');
        this.route.navigate(['store/not-found/']);
      }
    });
  }

  async getStoreName() {
    try {
      const data = await this.apiService.getStoreDisplayName(this.storeId, this.app.tenantId).toPromise();
  
      if (data.status === "SUCCESS") {
        this.storeName = data.name;
        this.formattedAddress = data.formattedAddress;
      }else{
        this.route.navigate(['store/not-found/' + this.storeId]);
      }
  
      if (this.formattedAddress) {
        await this.getReviews(this.formattedAddress);
      } else {
        console.error("Formatted Address is undefined or null");
      }
    } catch (error) {
      console.error("Error fetching store name:", error);
    }
  }
  
  async getReviews(addr: any) {
    try {
      if (!addr) {
        console.error("Address is undefined or null");
        return;
      }
      const data = await this.apiService.getformattedAddress(addr, this.app.tenantId).toPromise();
        if (data.reviews && data.reviews.length > 0) {
          this.apiService.reviews.next(data.reviews);
        } else {
          this.apiService.getReviews(data.lat, data.lng).subscribe((review: any) => {
            this.apiService.reviews.next(review);
          })
        }
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  }
  
  getBanners(): void {
    this.apiService.getTenantContent(this.app.tenantId).subscribe((data) => {
      this.consentText = data.singleStorePage.consentText;
  
      if (data.singleStorePage.banner) {
        const desktopBanners = data.singleStorePage.banner.desktop || [];
        const mobileBanners = data.singleStorePage.banner.mobile || [];
        this.bannerConfiguration = data.singleStorePage.banner;
        this.bannerAutoPlay = data.singleStorePage.banner.autoplay;
        if (window.innerWidth <= 768) {
          this.bannerImages = [...mobileBanners];
        } else {
          this.bannerImages = [...desktopBanners];
        }
        if (this.bannerImages.length > 0) {
          setTimeout(() => {
            this.initializeCarousel(this.bannerConfiguration);
          }, 300);
        }
      }
    });
  }
  
  
  initializeCarousel(bannerConfig: any) {
    const bannerContainer = $('#bannerCarousel');
    bannerContainer.empty(); 
  
    this.bannerImages.forEach((banner: any, count: number) => {
      const image = $('<img>').attr('src', banner.url).addClass('half-image');
      const anchor = $('<a>').attr('href', banner.ctaUrl).attr('target', '_blank').append(image);
      const linkText = $('<span>').attr('class', 'sr-only').text(banner.imgDesc);
      anchor.append(linkText);
  
      const bannerImage = $('<div>').addClass('banner-img1')
        .attr("aria-live", "slide " + (count + 1) + " of " + this.bannerImages.length)
        .attr("aria-label", "slide " + (count + 1) + " of " + this.bannerImages.length)
        .append(anchor);
      
      bannerContainer.append(bannerImage);
    });
  
    if (this.bannerImages.length > 1) {
      bannerContainer.slick({
        autoplay: bannerConfig.autoplay,
        autoplaySpeed: parseInt(bannerConfig.autoplaySpeed),
        infinite: bannerConfig.infinite,
        speed: parseInt(bannerConfig.speed),
        dots: bannerConfig.dots,
        arrows: bannerConfig.arrows
      });
    }
    
  }
  
  bannerAutoPlayFun(): void {
    this.bannerAutoPlay = !this.bannerAutoPlay;
    $('#bannerCarousel').slick('slickSetOption', 'autoplay', this.bannerAutoPlay, true);
  }
 
  get myForm() {
    return this.enquiryLeadForm.controls;
  }
 
  onSubmit(event: any) {
    event.preventDefault();
    this.submitted = true;
  
    if (
      !this.enquiryLeadForm.controls['name'].valid ||  
      !this.enquiryLeadForm.controls['email'].valid || 
      !this.enquiryLeadForm.controls['mobileNo'].valid || 
      !this.enquiryLeadForm.controls['pincode'].valid || 
      !this.enquiryLeadForm.controls['consent'].value 
    ) {
      this.utils.focusFirstInvalidField(this.enquiryLeadForm); 
      return; 
    }
  
    this.enquiryLeadForm.patchValue({
      storeId: this.storeId, 
      sourceName: "SINGLESTOREPAGEEVENT",
    });
  
    let lead = new Lead(
      this.enquiryLeadForm.value.name,
      this.enquiryLeadForm.value.email,
      this.enquiryLeadForm.value.mobileNo,
      this.enquiryLeadForm.value.pincode,
      this.enquiryLeadForm.value.sourceName,
      this.enquiryLeadForm.value.storeId,
      this.enquiryLeadForm.value.consent
    );
  
    if (this.enquiryLeadForm.valid) {
      this.submitText = 'Loading...'; 
  
      this.apiService.saveLead(lead, this.app.tenantId)
        .pipe(debounceTime(700)) 
        .subscribe(
          (data) => {
            this.submitText = 'Submit'; 
            if (data.status === 'SUCCESS') {
              this.enquiryLeadForm.reset();
              this.enquiryLeadForm.markAsPristine();
              this.enquiryLeadForm.markAsUntouched();
              this.submitted = false;
              this.storDetails = null;
  
              this.modalService.open(this.thankYouModal, { centered: true });
              this.description = data.desc;
            } else {
              this.description = data.desc;
            }
          },
          (err) => {
            console.error('Error:', err);
            this.submitText = 'Submit'; 
            this.submitted = false; 
          }
        );
    }
  }
  
  
  getMobileNumber(storDetails: any, vfn: string) {
    return ((storDetails && storDetails.phoneNumber) ? "tel:" + storDetails : "tel:" + vfn);
  }

  productSlider() {
    $(document).ready(function () {
      $(".storeList").slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        loop: true,
        speed: 100,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear",
        asNavFor: '.slider_dots',
        dots: false,
        responsive: [{
          breakpoint: 700,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        ],
      });

      $('.slider_dots').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.storeList',
        arrows: false,
        dots: false,
        centerMode: true,
        focusOnSelect: true,
        centerPadding: '20%',
      });
    })
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  handlePasteNumber(event: any){
    const value = event.clipboardData.getData('Text');
    const pattern =  /[0-9]/g
    if(value.match(pattern).length !== value.length){
      event.preventDefault()
    }
  }
}
